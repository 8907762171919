<template>
  <div id="heatmap"></div>
</template>
<script setup>
import goongjs from '@goongmaps/goong-js';
import { onMounted } from 'vue';
import { ref, defineEmits } from 'vue';
import { MARKER_TYPE } from '@/util/common-constant.js';

goongjs.accessToken = window.VUE_APP_API_GOONG_MAP_TOKEN;
const emits = defineEmits(['click-marker', 'click-marker-ts']);
const props = defineProps({
  center: {
    type: Object,
    default: () => {
      return { lat: 21.051528423347538, lng: 105.80015809649605 };
    },
  },
  markers: {
    type: Array,
    default: () => [],
  },
});
const iconType = {};
iconType[MARKER_TYPE.CAMERA_SELECTED] = {
  icon: require('@/static/img/map/camera.png'),
  classAnimate:
    'animate-ping top-0 l-0 absolute inline-flex h-[30px] w-[30px] bg-ems-main1 !opacity-25 rounded-full',
  class: 'top-[21px] left-0',
};
iconType[MARKER_TYPE.CAMERA_DEFAULT] = {
  icon: require('@/static/img/map/camera-default.png'),
  classAnimate: '',
  class: 'top-[21px] left-0',
};
iconType[MARKER_TYPE.DEFAULT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.DO] = {
  icon: require('@/static/img/map/do.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 l-0 absolute inline-flex h-[30px] w-[30px] bg-ems-main1 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.VANG] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.VANG_SELECTED] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 l-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro3_600 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.CURRENT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-[-15px] left-[-15px] absolute inline-flex h-[60px] w-[60px]  bg-[#3A5EF6] !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.TRINH_SAT] = {
  icon: require('@/static/img/map/scout.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 l-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro4_600 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.LOCATION] = {
  icon: require('@/static/img/map/location.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
let map = null;
let markers = [];
onMounted(() => {
  map = new goongjs.Map({
    container: 'heatmap',
    style: 'https://tiles.goong.io/assets/goong_map_web.json', // stylesheet location
    center: [props.center.lng, props.center.lat], // starting position [lng, lat]
    zoom: 12, // starting zoom
    maxZoom: 16,
  });
  map.addControl(new goongjs.NavigationControl());
  if (props.markers && props.markers.length > 0) {
    let geojson = addMarkerAndGetGeoJson(props.markers);
    map.on('load', function () {
      map.addSource('heatmap', {
        type: 'geojson',
        data: geojson,
      });
      map.addLayer(
        {
          id: 'heatmap',
          type: 'heatmap',
          source: 'heatmap',
          paint: {
            // increase intensity as zoom level increases
            'heatmap-intensity': {
              stops: [
                [11, 1],
                [15, 3],
              ],
            },
            // use sequential color palette to use exponentially as the weight increases
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(46, 181, 18, 0)',
              0.2,
              'rgba(119, 204, 103, 1)',
              0.4,
              'rgba(240, 220, 73, 1)',
              0.6,
              'rgba(245, 104, 35, 1)',
              0.8,
              'rgba(243, 58, 74, 1)',
            ],
            // increase radius as zoom increases
            'heatmap-radius': {
              stops: [
                [0, 5],
                [9, 50],
              ],
            },
          },
        },
        'water'
      );
    });
  }
});

const addMarkerAndGetGeoJson = (listData) => {
  const geojson = {
    type: 'FeatureCollection',
    features: [],
  };
  listData.forEach((mk) => {
    const iconImage = document.createElement('img');
    iconImage.src = iconType[mk.type].icon;
    iconImage.style.width = '20px';
    iconImage.style.height = '20px';
    let marker = new goongjs.Marker(iconImage).setLngLat([mk.lng, mk.lat]);
    marker.addTo(map);
    markers.push(marker);
    geojson.features.push({
      type: 'Feature',
      properties: {
        id: mk.id,
        mag: mk.weight,
      },
      geometry: { type: 'Point', coordinates: [mk.lng, mk.lat] },
    });
  });
  return geojson;
};
const setData = (listMarkers) => {
  for (let i = 0; i < markers.length; i++) {
    markers[i].remove();
  }
  markers = [];
  let geojson = addMarkerAndGetGeoJson(listMarkers);
  map.getSource('heatmap').setData(geojson);
};
const moveToLocation = (lat, lng) => {
  map.flyTo({
    center: [lng, lat],
  });
};
defineExpose({
  moveToLocation,
  setData,
});
</script>

<style lang="scss">

#heatmap {
  height: 700px;
  .mapboxgl-popup-content {
    background-color: #0F0F15;
    padding: 16px !important;
    .mapboxgl-popup-close-button {
      right: 4px;
      top: 4px;
      color: #fff;
      font-size: 20px;
    }
  }
  .mapboxgl-popup-tip {
    border-top-color: #0F0F15;
  }
}

</style>
