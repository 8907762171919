<template>
  <div class="common-io h-[calc(100%-50px)]">
    <div class="grid grid-cols-2 gap-x-[20px] mb-[12px]">
      <div class="relative">
        <cds-select
          class="h-[34px]"
          :placeholder="t('object_tracing.cam-group')"
          :options="groupCamera"
          v-model:value="formState.groupCameraIds"
          :allow-clear="true"
          :label-prop="'groupNames'"
          :value-prop="'id'"
          @change="changeGroup"
          multiple
          :maxTagCount="1"
        >
        </cds-select>
        <cds-feather-icons
          v-if="formState.groupCameraIds.length === 0"
          type="chevron-down"
          class="text-ems-gray400 absolute right-2 inline-flex items-center h-full"
        />
      </div>
      <div class="relative">
        <cds-select
          class="h-[34px]"
          :placeholder="t('object_information.camera')"
          :options="listCam"
          v-model:value="formState.cameraIds"
          :allow-clear="true"
          :label-prop="'name'"
          :value-prop="'id'"
          @change="onSearch"
          multiple
          :maxTagCount="1"
        >
        </cds-select>
        <cds-feather-icons
          v-if="formState.cameraIds.length === 0"
          type="chevron-down"
          class="text-ems-gray400 absolute right-2 inline-flex items-center h-full"
        />
      </div>
    </div>

    <cds-from-to-date
      v-model:dateFrom="formState.dateFrom"
      v-model:dateTo="formState.dateTo"
      :is-show-label="false"
      :allow-clear="true"
      class="from-to-date mb-[14px]"
      @update:dateFrom="onSearch"
      @update:dateTo="onSearch"
    />
    <div v-if="isLoading" class="spin bg-transparent h-[400px]">
      <a-spin />
    </div>
    <div v-else>
      <Empty
        v-if="!locationData || locationData.length === 0"
        :description="t('common.no_data_displayed')"
        class="mt-[60px]"
      />
      <div
        v-else
        class="flex flex-col gap-[16px] cursor-pointer max-h-[calc(100vh-420px)] overflow-y-auto pr-1"
      >
        <div
          v-for="item in locationData"
          :key="item.camera.id"
          class="p-16px rounded-lg flex gap-[20px] items-center bg-ems-tag1 border-1px border-solid overflow-x-clip"
          :class="
            currentCamera === item.camera.id
              ? 'border-[#FF0000]'
              : 'border-ems-tag1'
          "
          @click="selectCamera(item)"
        >
          <img
            :src="
              !item.imageCropUrl
                ? require('@/static/img/no-img.png')
                : item.imageCropUrl
            "
            width="120"
            height="120"
            class="rounded-lg border-1 border-solid border-ems-gray300 mr-1 w-[120px] h-[120px] object-cover"
          />
          <div class="flex flex-col justify-center gap-[12px]">
            <span class="text-lg text-ems-gray100 font-semibold">{{
              item.camera.name
            }}</span>

            <span class="text-base text-ems-gray300">{{
              item.camera.name
            }}</span>
            <span class="text-sm text-ems-gray200"
              >{{ t('object_tracing.history') }}
              <span class="text-sm font-semibold text-ems-main1">{{
                item.count
              }}</span></span
            >
            <span
              class="rounded-full w-[fit-content] block text-[12px] text-ems-gray300 px-[16px] py-[4px] border-1px border-solid border-ems-gray300 cursor-pointer"
              @click="viewEvent(item)"
            >
              {{ t('object_tracing.view-event') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, defineEmits, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Empty from '@/components/empty/index.vue';
import dayjs from "dayjs";

const props = defineProps({
  camInfo: {
    type: Object,
    default: () => {},
  },
  isLoading: {
    default: false,
  },
  groupCamera: {
    type: Array,
    default: () => [],
  },
});
const listCam = ref(Object.values(props.camInfo));
const emits = defineEmits(['selectCamera', 'on-search-data']);
const { t } = useI18n();
const { state, dispatch } = useStore();
const route = useRoute();
const locationData = computed(() => state.tracingObject.locationData);
const tracingObjectDetail = computed(
  () => state.tracingObject.tracingObjectDetail
);
const currentCamera = ref(null);
const formState = reactive({
  groupCameraIds: [],
  cameraIds: [],
  dateFrom: dayjs().subtract(30, 'day'),
  dateTo: dayjs(),
});
onMounted(async () => {});
const selectCamera = (item) => {
  currentCamera.value = item.camera.id;
  emits('selectCamera', item);
};
const onSearch = () => {
  emits('on-search-data', {
    id: route.query.id,
    params: {
      groupCameraIds: formState.groupCameraIds.join(','),
      cameraIds: formState.cameraIds.join(','),
      dateFrom: formState.dateFrom,
      dateTo: formState.dateTo,
    },
  });
  currentCamera.value = '';
};
const setCameraId = (id) => {
  formState.cameraIds = [id];
  currentCamera.value = '';
};
const router = useRouter();
const viewEvent = (item) => {
  router.push({
    path: '/trace-management/identification-event',
    query: {
      searchCamera: item.camera.id,
      searchName: route.query.id,
      isTracing: true,
      idTracing: route.query.id,
      tab: route.query.tab,
      subTab: route.query.subTab,
    },
  });
};
const changeGroup = () => {
  onSearch();
  let cam = {};
  if (formState.groupCameraIds.length === 0) {
    cam = props.camInfo;
  } else {
    for (let group of props.groupCamera) {
      if (formState.groupCameraIds.includes(group.id)) {
        for (let id of group.cameraId) {
          if (props.camInfo[id]) {
            cam[id] = props.camInfo[id];
          }
        }
      }
    }
  }
  listCam.value = Object.values(cam);
};
defineExpose({ setCameraId });
</script>
<style lang="scss">
.common-io .from-to-date .ant-row .ant-col:first-child {
  padding-left: 0 !important;
}
.common-io .from-to-date .ant-row .ant-col:last-child {
  padding-right: 0 !important;
}
.common-io .from-to-date .ant-row .ant-picker {
  border-radius: 0 !important;
  height: 34px !important;
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-style: none;
}
.common-io .ant-select-selector {
  overflow-x: auto !important;
  padding-right: 0;
  padding-left: 4px;
}
.common-io .ant-select-selector .ant-select-selection-overflow {
  flex-wrap: nowrap;
}
.common-io .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 89, var(--tw-bg-opacity));
  border-radius: 20px;
  border-style: none;
  margin-right: 0.25rem;
}
.common-io .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .ant-select-selection-item-content {
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
.common-io .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .anticon-close {
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
.common-io .ant-select-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  margin-top: 2px;
  top: 0px;
  right: 0.25rem;
}
</style>
